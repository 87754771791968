"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var columsConfig_1 = require("./columsConfig");
var clone_utils_1 = require("@common/utils/clone.utils");
var applyDrag = function (arr, dragResult) {
    var removedIndex = dragResult.removedIndex, addedIndex = dragResult.addedIndex, payload = dragResult.payload;
    if (removedIndex === null && addedIndex === null)
        return arr;
    var result = __spreadArray([], arr, true);
    var itemToAdd = payload;
    if (removedIndex !== null)
        itemToAdd = result.splice(removedIndex, 1)[0];
    if (addedIndex !== null)
        result.splice(addedIndex, 0, itemToAdd);
    return result;
};
exports.default = (0, composition_api_1.defineComponent)({
    name: 'SortColumn',
    props: ['columns', 'change', 'showSort', 'target', 'defaultColumns', 'canSearch', 'needReset'],
    model: {
        prop: 'columns',
        event: 'change'
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var innerColumns = (0, composition_api_1.ref)([]);
        var sortableColumns = (0, composition_api_1.computed)(function () {
            return props.target === 'userlist' ? innerColumns.value : innerColumns.value.slice(2);
        });
        var columsConfigClone = clone_utils_1.default.deep(props.defaultColumns || columsConfig_1.default);
        (0, composition_api_1.watch)(function () { return props.showSort; }, function () {
            if (props.showSort)
                innerColumns.value = clone_utils_1.default
                    .deep(props.columns)
                    .sort(function (column) { return (column.key === 'name' ? -1 : 0); })
                    .sort(function (column) { return (column.key === 'thumbnail' ? -1 : 0); });
        });
        var handleSelectedChange = function (key) {
            var target = innerColumns.value.findIndex(function (column) { return column.key === key; });
            if (target === -1)
                innerColumns.value = __spreadArray(__spreadArray([], innerColumns.value, true), [
                    clone_utils_1.default.deep(columsConfigClone.find(function (column) { return column.key === key; }))
                ], false);
            else {
                var newVal = innerColumns.value;
                newVal.splice(target, 1);
                innerColumns.value = newVal;
            }
        };
        var handleClickSave = function () {
            var change = clone_utils_1.default.deep(innerColumns.value);
            emit('change', change);
            emit('close');
        };
        var handleResetSave = function () {
            console.log('handle reset save');
            emit('reset');
            emit('close');
        };
        var handleDrop = function (dropResult) {
            if (props.target === 'userlist') {
                var draggableList = innerColumns.value;
                innerColumns.value = __spreadArray([], applyDrag(draggableList, dropResult), true);
            }
            else {
                var draggableList = innerColumns.value.slice(2);
                innerColumns.value = __spreadArray([
                    innerColumns.value[0],
                    innerColumns.value[1]
                ], applyDrag(draggableList, dropResult), true);
            }
        };
        var defaultRenderColumns = (0, composition_api_1.computed)(function () {
            return columsConfigClone === null || columsConfigClone === void 0 ? void 0 : columsConfigClone.map(function (column) { return (__assign(__assign({}, column), { selected: Boolean(innerColumns.value.find(function (currentColumn) { return currentColumn.key === column.key; })) })); }).filter(function (item) {
                return item.key !== 'thumbnail' &&
                    (searchKey.value !== ''
                        ? item.name.toLowerCase().includes(searchKey.value.toLowerCase())
                        : true);
            });
        });
        var searchKey = (0, composition_api_1.ref)('');
        return {
            handleDrop: handleDrop,
            handleResetSave: handleResetSave,
            handleSelectedChange: handleSelectedChange,
            handleClickSave: handleClickSave,
            defaultRenderColumns: defaultRenderColumns,
            innerColumns: innerColumns,
            sortableColumns: sortableColumns,
            searchKey: searchKey
        };
    }
});
