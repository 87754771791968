"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var defaultColums = [
    { key: 'thumbnail', name: '', width: '156px', fixed: true, sortable: false },
    { key: 'name', name: 'campaign_report.col_name', width: '460px', fixed: false, sortable: true, dataType: 'string' },
    {
        key: 'engageView',
        name: 'campaign_report.col_view',
        width: '108px',
        sortable: true,
        dataType: 'number',
        mainText: 'count',
        tips: 'campaign_report.col_view_tip'
    },
    {
        key: 'engageClick',
        name: 'campaign_report.col_click',
        width: '108px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        tips: 'campaign_report.col_click_tip'
    },
    {
        key: 'engageSubmit',
        name: 'campaign_report.col_form_submit',
        width: '160px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        align: 'center',
        tips: 'campaign_report.col_form_submit_tip'
    },
    {
        key: 'engageClose',
        name: 'campaign_report.col_close',
        width: '108px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        tips: 'campaign_report.col_close_tip'
    },
    {
        key: 'engageGoal',
        name: 'campaign_report.col_goal',
        width: '166px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        secondaryText: 'count',
        tips: 'campaign_report.col_goal_tip'
    },
    //  { key: 'engageExit', name: 'Exit', width: '120px', sortable: true, dataType: 'number', mainText: 'rate', secondaryText: 'count' },
    {
        key: 'avgVisitTime',
        name: 'campaign_report.col_visit_duration',
        width: '200px',
        sortable: true,
        dataType: 'number',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_visit_duration_tip'
    },
    {
        key: 'bounceRate',
        name: 'campaign_report.col_bounce_rate',
        width: '172px',
        sortable: true,
        dataType: 'number',
        mainText: 'rate',
        align: 'center',
        tips: 'campaign_report.col_bounce_rate_tip'
    },
    {
        key: 'sessionViewDepth',
        name: 'campaign_report.col_session_depth',
        width: '205px',
        sortable: true,
        dataType: 'number',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_session_depth_tip'
    },
    {
        key: 'createTime',
        name: 'campaign_report.col_create_time',
        width: '192px',
        sortable: true,
        dataType: 'string',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_create_time_tip'
    },
    {
        key: 'createBy',
        name: 'campaign_report.col_creater',
        width: '220px',
        sortable: true,
        dataType: 'string',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_creater_tip'
    },
    {
        key: 'updateTime',
        name: 'campaign_report.col_update_time',
        width: '220px',
        sortable: true,
        dataType: 'string',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_update_time_tip'
    },
    {
        key: 'updateBy',
        name: 'campaign_report.col_updater',
        width: '232px',
        sortable: true,
        dataType: 'string',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_updater_tip'
    },
    {
        key: 'lastRunningPeriod',
        name: 'campaign_report.col_last_release',
        width: '220px',
        sortable: true,
        dataType: 'string',
        mainText: 'count',
        align: 'center',
        tips: 'campaign_report.col_last_release_tip'
    }
];
exports.default = defaultColums;
